.themeSubHeading {
  color: #161616 !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px;
  letter-spacing: 0.55px !important;
}

.themeSHeading {
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px;
  letter-spacing: 0.55px !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.themeBtn {
  height: 64px !important;
  padding: 10px 30px !important;
  border-radius: 85px !important;
  background: #0bd7f3 !important;
  text-transform: none !important;
  color: #fff !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
}

.themeBtn:hover {
  background: #09b1c3 !important;
}

.rightSmallSThemeBox {
  display: none !important;
}

@media screen and (max-width: 1038px) {
  .rightThemeBox {
    display: none !important;
  }
  .rightSmallSThemeBox {
    width: 90% !important;
    margin: auto !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
  .theneSectionContainer {
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 50px !important;
  }
}

@media screen and (max-width: 568px) {
  .themeSecImg {
    display: none !important;
  }
  .themeSectionSecondImg {
    display: flex !important;
  }
  .themeSubHeading {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 468px) {
  .themeSubHeading {
    font-size: 1.5rem !important;
  }
}
