.faqTitle {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.18px !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.faq-title {
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.18px !important;
}

.anyText {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.48px;
  background: linear-gradient(109deg, #00bce0 3.21%, #f52bff 39.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 992px) {
  .logoIcon {
    width: 400px !important;
  }
}

@media screen and (max-width: 868px) {
  .faqMainContainer {
    display: grid !important;
    justify-content: center !important;
  }
  .faqHeadinBox {
    width: 100% !important;
    text-align: center !important;
    margin-bottom: 20px !important;
  }
  .faqSecHeading {
    width: 100% !important;
  }
  .faqContainer {
    width: 100% !important;
  }
  .logoIconBox {
    display: none !important;
  }
}

@media screen and (max-width: 568px) {
  .faqSecHeading {
    font-size: 2rem !important;
  }
  .anyText {
    font-size: 2rem !important;
  }
  .faqTitle {
    font-size: 16px !important;
  }
  .faq-title {
    font-size: 16px !important;
  }
  .faqDescription {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 468px) {
  .faqSecHeading {
    font-size: 1.5rem !important;
  }
  .anyText {
    font-size: 1.5rem !important;
  }
}
