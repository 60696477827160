body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden !important;
  top: 0px !important;
}

/* ::-webkit-scrollbar {
  width: 0;
} */

/* settings.js */
@media screen and (max-width: 868px) {
  .mainContainer {
    padding-right: 0px !important;
  }
  .settingMainContainer {
    margin-top: 20px !important;
    width: 100% !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 1115px) {
  .previewSubContainer {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .settingMainContainer {
    width: 100% !important;
    margin: auto !important;
  }
}

/* Preview.jsx // MobilePreview.js*/
.iconButton {
  margin-right: 25px !important;
}

@media screen and (max-width: 768px) {
  .iconButton {
    margin-right: 0px !important;
  }
}

.previewSubContainer {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.dashboardLinksPreview {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.device-frame-container {
  transform: scale(0.5);
  transform-origin: center;
  display: flex !important;
  justify-content: center;
  height: 100vh !important;
}

.previewSubContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll !important;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.searchDiv {
  padding-right: 0px !important;
}

.searchDiv input {
  width: 50% !important;
  margin-inline: 0px !important;
  padding: 7px !important;
  margin-right: 10px !important;
}
.deviceBtn {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

@media screen and (max-width: 1115px) {
  .linksComponentDiv {
    width: 60% !important;
    padding-right: 20px !important;
  }
  .previewComponentDiv {
    width: 50% !important;
  }
  .previewSettingComponentDiv {
    width: 50% !important;
  }
  .deviceBtn {
    font-size: 13px !important;
  }
  .deviceDiv {
    display: block !important;
  }
  .deviceBtnDiv {
    display: none !important;
  }
  .marvel-device.iphone-x .notch {
    position: absolute !important;
    left: 108px !important;
  }
}

@media screen and (max-width: 868px) {
  .previewSettingComponentDiv {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .linksComponentDiv {
    width: 100% !important;
    margin: auto !important;
    border-right: none !important;
    padding-right: 0px !important;
  }

  .previewComponentDiv {
    display: none !important;
  }
}

::placeholder {
  padding-left: 5px;
}

/* Themes.jsx */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center !important;
  align-items: center !important;
  gap: 20px;
}

@media screen and (max-width: 1115px) {
  .gridContainer {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 568px) {
  .gridContainer {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    justify-content: center !important;
  }
}

/* Links.js */
.previewBtnDiv {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .previewBtnDiv {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex !important;
    justify-content: center;
    align-items: end;
    height: 100vh;
    padding-bottom: 20px;
    z-index: 999;
    width: 100%;
    height: auto;
  }

  .previewBtnDiv button {
    border-radius: 44px;
    box-shadow: 0 6px 12px #0a0b0d14;
    padding: 8px 18px;
    transition: width 0.2s, padding 0.2s;
    font-weight: 500;
    background-color: #f2f2f2;
  }

  .btn.btn-outline-primary.shadow-none {
    margin: 0 auto;
  }
}

.copyBtn {
  border: 1px solid #4f8b33;
  color: #4f8b33 !important;
}
.copyBtn:hover {
  color: #fff !important;
  background-color: #4f8b33 !important ;
}

/* View.js */
.searchInput {
  border-radius: 20px !important;
  padding: 6px !important;
  width: 20% !important;
  margin-bottom: 5px;
  margin-inline: 10px !important;
}

@media screen and (max-width: 768px) {
  .searchInputDiv {
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
    margin: 0px !important;
    width: 100% !important;
    margin: auto !important;
    padding-right: 44px !important;
  }
  .searchInput {
    width: 40% !important;
    padding: 7px 12px !important;
  }
}

.searchInput {
  padding: 7px 12px !important;
}

@media screen and (max-width: 568px) {
  .searchInputDiv {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 5px !important;
    padding-right: 0px !important;
    width: 85% !important;
    margin: auto;
  }
  .searchInput {
    border-radius: 50px !important;
    width: 100% !important;
  }
}

/* Navbar.js */
@media screen and (max-width: 868px) {
  .instructionVideoBox {
    width: 90% !important;
  }
}
@media screen and (max-width: 868px) {
  .ifreamVideoBox {
    width: 90% !important;
  }
}

.registerButton {
  width: 120px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #f1f1f1;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
  background-color: #f8f8f8;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registerButton:hover {
  background-color: #d6d6d6;
  border-color: #cccccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.loginButton {
  width: 110px;
  height: 40px;
  border-radius: 100px;
  background-color: #1f1f1f;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  border: 1px solid #fff;
}
.inVideo {
  width: 162px;
  height: 40px;
  border-radius: 100px;
  background-color: #1f1f1f;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  border: 1px solid #fff;
  margin-right: 10px !important;
}

.loginButton:hover {
  background-color: #373737;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-backdrop {
  z-index: inherit !important;
  background: none !important;
}

/* CopySettings.js */
@media screen and (max-width: 1115px) {
  .linksSettingComponentDiv {
    width: 60% !important;
  }
}
@media screen and (max-width: 868px) {
  .linksSettingComponentDiv {
    width: 100% !important;
    margin: auto !important;
    border-right: none !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 568px) {
  .footerSettingContainer {
    display: none !important;
  }
  .infoIcon {
    display: none !important;
  }
  .footer-setting {
    display: block !important;
  }
  .footerSettingDiv {
    display: block !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .footerAndSearchDiv {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .linksAndPaginationDiv {
    margin-top: 10px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .descriptionSetting {
    display: block !important;
  }
  .textArea {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .headingInput {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .descAddBtn {
    display: none !important;
  }
  .descBtn {
    margin-top: 10px !important;
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    gap: 10px;
  }
  .socialMediaIconDiv {
    display: block !important;
  }
  .selectTag {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .socialInput {
    width: 100% !important;
    margin-bottom: 10px;
  }
  #addSocialIconBtn {
    float: right !important;
  }

  .previewIconDiv {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 10px !important;
  }

  .copyButton {
    border: 1px solid #4f8b33;
    color: #4f8b33 !important;
  }
  .copyButton:hover {
    color: #fff !important;
    background-color: #4f8b33 !important ;
  }
  .linkAddressInput {
    width: 100% !important;
  }
  .addressDiv {
    display: block !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .copyBtn {
    display: none !important;
  }
  .editAddressBtn {
    display: none !important;
  }
  .editIconAndCopyIconDiv {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    gap: 10px !important;
    margin-top: 10px !important;
    width: 100% !important;
  }
  .eyeBtn {
    margin-left: 45% !important;
    margin-bottom: 10px !important;
    border-radius: 0.25rem !important;
  }
}

@media screen and (max-width: 468px) {
  .logoContainer {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    align-items: center !important;
    gap: 20px !important;
  }
  .logo-div {
    display: flex !important;
    align-items: center !important;
    gap: 20px !important;
  }
}

.modalSaveBtn:hover {
  transition: background-color 0.3s ease !important;
  background-color: #376927 !important;
}
.modalCloseBtn:hover {
  transition: background-color 0.3s ease !important;
  color: #4f8b33;
  border: 1px solid #4f8b33;
  background: #fff9e6;
}

/* LinksItem.js */
@media screen and (max-width: 1067px) {
  .linksSettingDiv {
    display: block !important;
  }
  .linksSettingsIocns {
    margin-top: 5px;
  }
}
@media screen and (max-width: 868px) {
  .linkHeading {
    font-size: 16px !important;
  }
  .linkDescription {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 468px) {
  .linkHeading {
    font-size: 16px !important;
  }
  .linkDescription {
    font-size: 14px !important;
  }
  .headingContainer {
    margin-bottom: 5px !important;
  }
  .linksSettingsIocns {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 5px;
  }
  .switch-big {
    display: none !important;
  }
  .switch-small {
    display: flex !important;
  }
}

/* Dashboard.jsx */
.addMoreBtn {
  flex-shrink: 0 !important;
  text-transform: none !important;
  border: 1px solid #ecffe9 !important;
  background: #f2ffef !important;
  color: #4d8733 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0.48px !important;
}
.createBtn {
  border-radius: 85px !important;
  background: #0bd7f3 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.16px !important;
  text-transform: none !important;
}

.createBtn:hover {
  background-color: #09b1c3 !important;
}

@media screen and (max-width: 568px) {
  .muiModalBox {
    width: 90% !important;
  }
  .createBtnBox {
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0 !important;
  }
  .createBtn {
    width: 90% !important;
    margin: auto !important;
  }
}
.usernameInput {
  border: 1px solid #b4d33b;
  outline: none !important;
  padding: 8px;
  border-radius: 4px;
}

.usernameInput:hover {
  border: 1px solid #4d8733 !important;
}
.usernameInput:focus {
  border: 2px solid #4d8733 !important;
}

.userEmailInput {
  border: 1px solid #b4d33b;
  outline: none !important;
  padding: 8px;
  border-radius: 4px;
}

.userEmailInput:hover {
  border: 1px solid #4d8733 !important;
}
.userEmailInput:focus {
  border: 1px solid #4d8733 !important;
}

::placeholder {
  font-size: 14px;
  color: #777e90 !important;
}

/* Signup.js */
@media screen and (max-width: 468px) {
  .signupFormDiv {
    width: 90% !important;
  }
}

/* Addlink.js */
@media screen and (max-width: 568px) {
  .addlinkButton {
    font-size: 14px !important;
  }
  /* NotLivePage */
  .infoText {
    font-size: 16px !important;
  }
}

/* CheckPassword.jsx */
.checkPasswordHeading {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  line-height: normal;
}

.toLoginText {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.modalContainer {
  border-radius: 16px !important;
}
.modalEyeIcon:hover {
  color: #77a4f2 !important;
  transition: all 0.3s ease;
}

.eyeIcon:hover {
  color: #00bce0 !important;
  transition: all 0.3s ease;
}

@media screen and (max-width: 568px) {
  .modalContainer {
    width: 90% !important;
  }
}

@media screen and (max-width: 468px) {
  .checkFormHeading {
    font-size: 18px !important;
  }
  .lockIcon {
    width: 55px !important;
    height: 55px !important;
  }
}

/* Link.js */
@media screen and (max-width: 992px) {
  .linksDiv {
    width: 75% !important;
  }
}

@media screen and (max-width: 768px) {
  .linksDiv {
    width: 85% !important;
  }
}

@media screen and (max-width: 468px) {
  .linksDiv {
    width: 85% !important;
  }
}

/* CheckPassword.js */
.checkPasswordInput:hover {
  border: 1px solid #55dffa !important;
  transition: all 0.3s ease;
}
.checkPasswordInput:focus {
  border: 1px solid #00bce0 !important;
}

@media screen and (max-width: 468px) {
  .formControl {
    width: 90% !important;
  }
  .checkPasswordBtn {
    width: 35% !important;
  }
}

/* users.js */
th,
td {
  text-align: center;
  padding: 12px !important;
  border: none !important;
  border-top: 1px solid #dddfe2 !important;
  border-bottom: 1px solid #dddfe2 !important;
  color: #7d8690 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px;
}

th {
  color: #67757c !important;
  letter-spacing: 0.15px;
  font-size: 14px !important;
  font-weight: 550 !important;
  line-height: 24px;
  padding: 14px;
}

tr:hover {
  transition: all 0.3s ease;
}

.viewMainConainer {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* Add Link */
.input-hover-effect:hover {
  border-color: #3498db !important;
}

/* Style the select dropdown */
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

#google_translate_element select {
  width: 185px;
  border: 1px solid #00bce0;
  padding: 2px;
  outline: none !important;
  cursor: pointer;
  background-color: #fff;
  color: #00bce0;
  border-radius: 4px;
}

#google_translate_element {
  position: relative;
  top: 10px;
}

/* Remove the 'Powered by Google Translate' text */
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  /* font-size: 0px !important; */
}

/* Hide the 'Google Translate' text */
.goog-te-gadget span {
  display: none !important;
}
.goog-te-gadget .goog-te-combo {
  margin-top: 0 !important;
}

@media screen and (max-width: 468px) {
  .checkPasswordNav {
    padding: "10px" !important;
  }
}

/* Scrollbar styling */
/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%) !important;
  border-radius: 5px;
} */

/* Firefox */
::-moz-scrollbar {
  width: 12px;
}

::-moz-scrollbar-track {
  background-color: #f1f1f1;
}

::-moz-scrollbar-thumb {
  background: linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%) !important;
  border-radius: 5px;
}

.textArea {
  color: #212529 !important;
  caret-color: #212529 !important;
}
