.comName {
  background: var(
    --Gradation,
    linear-gradient(90deg, #00bce0 58.85%, #f52bff 84.87%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.72px !important;
}

.navItems {
  color: #1c1c1c !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
}
.navItems:hover {
  color: #00bce0 !important;
}

.signButton {
  padding: 5px 25px !important;
  border-radius: 85px !important;
  border: 1px solid var(--Gradation, #00bce0) !important;
  background: linear-gradient(
    110deg,
    rgba(245, 253, 255, 0.5) 3.48%,
    rgba(255, 245, 255, 0.5) 81.52%
  );
  color: #161616 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
}

.signButton:hover {
  color: #00bce0 !important;
}

/* Responsieve Nav */
.responsieveContainer {
  display: none !important;
}

.active {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.signup-active {
  padding: 5px 25px !important;
  border-radius: 85px !important;
  border: 1px solid var(--Gradation, #00bce0) !important;
  background: #f8f7ff !important;
  color: #161616 !important;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.activeLogIn {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.navLoginInItem {
  color: #1c1c1c !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
}
.navLoginInItem:hover {
  color: #00bce0 !important;
}

@media screen and (max-width: 1025px) {
  .navMainContainer {
    display: none !important;
  }

  .responsieveContainer {
    display: flex !important;
  }
}

/* Small screen */
.nav-items {
  color: #1c1c1c !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
}

.signupMenu {
  border-radius: 85px !important;
  border: 1px solid var(--Gradation, #00bce0) !important;
  background: var(
    --Gradation,
    linear-gradient(90deg, #00bce0 58.85%, #f52bff 84.87%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  margin-top: 5px !important;
}

.linksDropdown {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: 500 !important;
}

/* Menu style */
#accountMenu {
  display: block !important;
  position: absolute !important;
  top: 25px !important;
  left: -15px !important;
  background: #fff !important;
  border-radius: 16px !important;
  padding: 5px !important;
  z-index: 999 !important;
  min-width: 180px !important;
}

#menuItemDiv {
  display: flex;
  flex-direction: column;
}

.logouttt {
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

/* ReportAnIssueModal.jsx */
.report-issue-textField {
  background-color: white !important;
  border: 1px solid rgba(232, 232, 232, 1) !important;
}

.report-issue-textField :not(button, svg) {
  font-size: 14px !important;
}

.report-issue-textField input,
textarea {
  color: rgba(124, 124, 124, 1) !important;
}

.report-issue-textField ::after {
  display: none;
}

.report-issue-textField ::before {
  display: none;
}
