.leftHeadingBox {
  width: 50%;
}

.featuresHeading {
  color: #161616 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 66px;
  letter-spacing: 0.55px !important;
}

.powerText {
  background: linear-gradient(91deg, #00bce0 19.38%, #f52bff 79.7%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px;
  letter-spacing: 0.55px !important;
}

.createPageButton {
  height: 60px !important;
  padding: 10px 30px !important;
  border-radius: 85px !important;
  background: #0bd7f3 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.18px !important;
  margin-top: 50px !important;
  text-transform: none !important;
}

.createPageButton:hover {
  background: #09b1c3 !important;
}

.rightFeaturesCardBox {
  width: 50%;
  display: grid;
  align-items: center !important;
  justify-content: center !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 32px;
  /* flex-wrap: wrap; */
}

.featureCard {
  width: 223px;
  height: 193px;
  flex-shrink: 0;
  border-radius: 22px !important;
  background: #fff !important;
  box-shadow: 10px 4px 48.3px 0px rgba(188, 192, 208, 0.25) !important;
  padding: 42px 29px;
}

.featureImg {
  width: 38px !important;
  height: 39px !important;
}

.featuresText {
  color: #161616 !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0.2px !important;
}

@media screen and (max-width: 1115px) {
  .featureCard {
    left: 0 !important;
  }
}

@media screen and (max-width: 868px) {
  .featureMainContainer {
    display: block !important;
  }

  .leftHeadingBox {
    width: 100% !important;
    text-align: center !important;
  }

  .rightFeaturesCardBox {
    width: 100% !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 568px) {
  .mainFeatureBox {
    height: auto !important;
    padding-bottom: 100px !important;
  }

  .featureCard {
    width: 136.57px !important;
    height: 118.197px !important;
    padding: 22px 19px;
  }
  .featuresText {
    font-size: 12px !important;
  }
  .featureImg {
    width: 23px !important;
    height: 23px !important;
  }

  .mainFeatureBox {
    height: auto !important;
  }
  .rightFeaturesCardBox {
    padding-bottom: 50px !important;
  }
  .featuresHeading {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 468px) {
  .featuresHeading {
    font-size: 1.5rem !important;
  }
}
