/* ShowingNewFeaturesModal.jsx */
.render-feature-container {
  max-height: 420px !important;
  overflow-y: auto !important;
  padding: 5px 10px 10px 0px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.render-feature-container::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

.render-feature-container::-webkit-scrollbar-track {
  background: #eae7e7 !important;
}

.render-feature-container::-webkit-scrollbar-thumb {
  background: #00bce0 !important;
}

.showing-feature-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #dde1ec;
  padding-bottom: 10px;
}

.feature-search {
  width: 180px;
  padding: 5px 20px 5px 25px;
  border-radius: 60px;
  background: #f1f1f1;
  border: 1px solid transparent;
  outline: none;
  transition: border 0.3s ease;
  font-size: 12px;
}

.feature-search::placeholder {
  color: #181818;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
}
.feature-search:hover {
  border: 1px solid #dde1ec;
}

.feature-search-icon {
  position: absolute;
  left: 10px;
}

.uploadBtn {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  height: 52.394px !important;
  padding: 0px 32.445px !important;
  align-items: center;
  border-radius: 286.181px !important;
  text-transform: none !important;
  border: 1.664px solid rgba(165, 158, 184, 0.25) !important;
  background: #fff !important;
  box-shadow: 21.63px 9.983px 37.2px 0px rgba(189, 180, 214, 0.16) !important;
}

.clearIcon {
  position: absolute !important;
  right: 52px !important;
  cursor: pointer;
}
