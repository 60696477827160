/* CustomerRating */

.customerReviewHeading {
  color: #161616;
  text-align: center;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px !important;
  letter-spacing: 0.55px !important;
}

.savingText {
  background: linear-gradient(146deg, #00bce0 4.76%, #f52bff 71.14%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px !important;
  letter-spacing: 0.55px !important;
}

.star-parent {
  /* display: flex;
  align-items: center !important;
  justify-content: center !important; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  z-index: 3;
}

.rectangle-parent9 {
  border-radius: 19px;
  background-color: #fff;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 27px 16px 49px 19px;
  box-sizing: border-box;
  gap: 4px !important;
  min-width: 212px;
  z-index: 2;
  margin-right: 20px !important;
}

.frame-child59 {
  width: 326px;
  height: 344px;
  display: flex;
  align-items: stretch;
  position: relative;
  border-radius: 19px;
  background-color: #fff;
  display: none;
  max-width: 100%;
}

.frame-parent37 {
  width: 280.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  gap: 3px;
}

.frame-wrapper31 {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end; */
}

.image-17-parent {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  z-index: 1;
}

.image-17-icon {
  height: 60px;
  width: 60px;
  position: relative;
  border-radius: 130.99px;
  object-fit: cover;
}

.frame-parent38 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.sarah-j {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 31px;
  font-weight: 500;
  display: inline-block;
  min-width: 70px;
}

.sarah-j-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.frame-parent39 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 4.2px;
  z-index: 1;
  margin-top: -5.1px;
  font-size: 11px;
  color: #6e7183;
}

.frame-icon4 {
  height: 15.2px;
  width: 15.2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.mumbaimaharashtra {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 31px;
  display: inline-block;
  min-width: 121px;
}

.frame-child60 {
  width: 23.6px;
  height: 23px;
  position: relative;
  z-index: 1;
}

.frame-wrapper32 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: row; */
  padding: 0 0 13px 22px;
  font-size: 13px;
}

.frame-parent40 {
  flex: 1;
  flex-direction: column;
  gap: 24px;
}

.cbxtree-has-streamlined-how-i-parent,
.frame-wrapper33 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.cbxtree-has-streamlined-how-i-parent {
  align-self: stretch;
  flex-direction: row;
}

.cbxtree-has-streamlined,
.frame-child61 {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
}
.cbxtree-has-streamlined {
  margin: 0;
  flex: 1;
  letter-spacing: 0.01em;
  line-height: 25px;
}

.cbxtree-has-streamlined-how-i-parent,
.frame-wrapper33 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper33 {
  flex-direction: column;
  padding: 88px 0 0;
}

.cbxtree-has-streamlined,
.frame-child61 {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
}

.frame-child61 {
  width: 23.6px;
  height: 23px;
}

.frame-wrapper34 {
  /* flex-direction: row; */
  /* padding: 0 59px; */
  margin-bottom: 17px;
  width: 100%;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* align-items: flex-start;
  justify-content: flex-start; */
}
.frame-child62,
.frame-child63,
.frame-child64,
.frame-child65,
.star-icon {
  height: 23px;
  width: 23px;
  position: relative;
  border-radius: 1.5px;
  min-height: 23px;
}
.frame-child62,
.frame-child63,
.frame-child64,
.frame-child65 {
  width: 22px;
}

.rectangle-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 23px 0 20px;
}

.frame-child66 {
  height: 3px;
  width: 120px;
  position: relative;
  background: linear-gradient(110.49deg, #00bce0, #f52bff);
  z-index: 1;
}

/* AddDomainSection.jsx */
.domainHeading {
  font-style: normal;
  font-weight: 500 !important;
  line-height: 60px;
  letter-spacing: 0.48px !important;
  color: #161616 !important;
}

.ownText {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.noteBox {
  display: flex !important;
  /* width: 81px; */
  /* height: 32px; */
  padding: 8px;
  border-radius: 33px !important;
  background: #10a2be !important;
  cursor: pointer !important;
}

.noteText {
  color: #fff;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 30.423px;
  letter-spacing: 0.14px !important;
}

.domainInputBox {
  width: 100% !important;
  padding: 15px 17px 15px 34px !important;
  margin-top: 10px !important;
  border-radius: 80px !important;
}

.getDomainBtn {
  text-transform: none !important;
  border-radius: 148px !important;
  border: 2px solid var(--new-gradation, #00bce0) !important;
  display: flex;
  /* height: 69px !important; */
  padding: 10px 20px !important;
  flex-shrink: 0;
}

.domainInput {
  width: 100%;
  border: none;
  outline: none;
}
.domainInput::placeholder {
  color: #6e7183;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.18px;
}

.domainSSInput {
  width: 100%;
  border: none;
  outline: none;
}

.domainSSInput::placeholder {
  color: #6e7183;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.18px;
}

@media screen and (max-width: 1115px) {
  .domainInput::placeholder {
    font-size: 16px !important;
  }
  .domainInput {
    display: none !important;
  }
  .domainSSInput {
    display: flex !important;
  }
}

@media screen and (max-width: 868px) {
  .domainInputBox {
    gap: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .domainHeading {
    font-size: 32px !important;
  }
  .ownText {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 668px) {
  .domainContainer {
    width: 90% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px !important;
  }
  .domainInput {
    display: none !important;
  }
  .domainSSInput {
    display: none !important;
  }
  .domainInputBox {
    background: inherit !important;
    display: flex !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 568px) {
  .addDomainMainContainer {
    height: auto !important;
    padding-bottom: 80px !important;
  }
}

@media screen and (max-width: 468px) {
  .ownText {
    font-size: 1.5rem !important;
  }
  .domainHeading {
    font-size: 1.5rem !important;
  }

  .noteBox {
    padding: 4px !important;
  }
}

/* Video Section styling*/
.howCbxText {
  color: #161616 !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px !important;
  letter-spacing: 0.55px !important;
}

.works {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 66px !important;
  letter-spacing: 0.55px;
}

.videoEmptyBox {
  border-radius: 30px !important;
  border: 2.5px solid var(--new-gradation, #00bce0) !important;
  width: 484px !important;
  height: 300px !important;
  flex-shrink: 0 !important ;
}

.videoBox {
  left: 50px;
  top: -50px;
  border-radius: 30px !important;
  height: 300px !important;
  /* flex-shrink: 0 !important ; */
  background-color: #fff;
  padding: 15px !important;
}

.videoBox img {
  width: 100%;
  border-radius: 30px;
}

@media screen and (max-width: 992px) {
  .videoAndHeadingContainer {
    display: flex !important;
    /* flex-direction: column !important;  */
    flex-direction: column-reverse !important;
    gap: 50px !important;
  }
  .videoHeading {
    display: flex !important;
    gap: 14px !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 668px) {
  .thumbnail {
    width: 100% !important;
    position: static !important;
  }
  .videoSectionMainContainer {
    height: auto !important;
    padding-bottom: 50px !important;
  }
  .videoAndHeadingContainer {
    width: 85% !important;
    gap: 0px !important;
  }
  .videoMainBox {
    position: none !important;
    width: 100% !important;
  }
  .videoEmptyBox {
    display: none !important;
  }
  .videoBox {
    width: 400px !important;
    position: static !important;
  }

  .customerReviewHeading {
    font-size: 45px !important;
  }

  .howCbxText {
    font-size: 45px !important;
  }

  .works {
    font-size: 45px !important;
  }
}

/* Headings */
@media screen and (max-width: 568px) {
  #reviews {
    height: auto !important;
    padding-bottom: 150px !important;
  }
  .howCbxText {
    font-size: 2rem !important;
  }
  .works {
    font-size: 2rem !important;
  }

  .customerReviewHeading {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 468px) {
  .howCbxText {
    font-size: 1.5rem !important;
  }
  .works {
    font-size: 1.5rem !important;
  }

  .customerReviewHeading {
    font-size: 1.5rem !important;
  }
}
