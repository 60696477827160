.heroSectionHeadingBox {
  display: block !important;
  /* flex-direction: column !important; */
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
  margin: auto !important;
}

.heroSectionHeading {
  color: #161616 !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 58px;
  letter-spacing: 0.5px !important;
}

.heroSubHeading {
  font-style: normal;
  line-height: 58px;
  letter-spacing: 0.5px !important;
}

.cbxtreeText {
  background: var(
    --Gradation,
    linear-gradient(90deg, #00bce0 58.85%, #f52bff 84.87%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.subHeading {
  color: #161616;
  text-align: center;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 31px;
  letter-spacing: 0.2px !important;
}

.heroSInput {
  width: 517px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 70px;
  border: 1px solid #eaeef2;
  background: #f4f7fa;
  outline: none;
  padding: 0px 230px 0px 20px;
}

.heroSInput::placeholder {
  color: #6c7076;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}

.createYourPBtn {
  /* flex-shrink: 0; */
  border-radius: 85px !important;
  background: #0bd7f3 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.16px !important;
  text-transform: none !important;
}

.createYourPBtn:hover {
  background-color: #09b1c3 !important;
}

.themeImg {
  position: absolute;
  right: 0%;
  bottom: 30px;
}

@media screen and (max-width: 1115px) {
  .createYourPBtn {
    right: 20% !important;
  }
}
@media screen and (max-width: 1028px) {
  .createYourPBtn {
    right: 10% !important;
  }
}

@media screen and (max-width: 783px) {
  .createYourPBtn {
    right: 0% !important;
  }
}

@media screen and (max-width: 627px) {
  .heroSInput {
    width: 100% !important;
  }
}

@media screen and (max-width: 868px) {
  .heroSectionHeading {
    font-size: 45px !important;
  }
  .subHeading {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 568px) {
  .heroSectionHeadingBox {
    width: 90% !important;
  }

  .heroSubHeading {
    font-size: 2rem !important;
  }
  .heroSectionHeading {
    font-size: 2rem !important;
  }
  .subHeading {
    font-size: 18px !important;
  }
  /* .inputAndBtnBox {
    position: static !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
  } */
  .createYourPBtn {
    height: 43px !important;
    font-size: 11px !important;
    min-width: auto !important;
    padding: 10px 20px !important;
    /* position: static !important;
    min-width: none !important;
    width: 257px !important;
    margin: auto !important; */
  }
  .heroSInput {
    padding: 0px 200px 0px 20px !important;
    width: 100% !important;
    height: 43px !important;
  }
  .heroSInput::placeholder {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 568px) {
  .themeimgForHeroSection {
    display: none !important;
  }
  .smallScreenTheme {
    display: flex !important;
  }
}

@media screen and (max-width: 468px) {
  .heroSectionHeading {
    font-size: 1.5rem !important;
  }
  .heroSubHeading {
    font-size: 1.5rem !important;
  }
  .subHeading {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1115px) {
  .inputAndBtnBox {
    top: 0px !important;
  }
  .heroSubHeading {
    margin-right: 0px !important;
  }
  .themeImgBox {
    height: auto !important;
    margin-top: 50px !important;
    padding-bottom: 50px !important;
    bottom: 0px !important;
  }
  .themeImg {
    position: static !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
