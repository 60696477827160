.toSignUpText {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.signupInputs {
  width: 100%;
  height: 52.394px;
  padding: 0px 32.445px;
  align-items: center;
  border-radius: 286.181px;
  outline: none;
  border: 1.664px solid rgba(165, 158, 184, 0.25);
  background: #fff;
  box-shadow: 21.63px 9.983px 37.2px 0px rgba(189, 180, 214, 0.16);
  margin-bottom: 20px;
}

.signupInputs::placeholder {
  color: #161616 !important;
  font-size: 16.638px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signupInputs:hover {
  border: 1px solid #86ddee !important;
  transition: all 0.3s ease;
}
.signupInputs:focus {
  border: 1px solid #00bce0 !important;
  /* border: "2px solid" !important;
  border-image: "linear-gradient(110deg, #00BCE0 3.48%, #F52BFF 81.52%) 1" !important; */
}

.enterButton {
  width: 132.276px !important;
  height: 52.394px !important;
  padding: 8.319px !important;
  border-radius: 65.722px !important;
  text-transform: none !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  box-shadow: none !important;
}

/* SignIn.jsx */
.signInInputs {
  width: 100%;
  height: 52.394px;
  padding: 0px 32.445px;
  align-items: center;
  border-radius: 286.181px;
  outline: none;
  border: 1.664px solid rgba(165, 158, 184, 0.25);
  background: #fff;
  box-shadow: 21.63px 9.983px 37.2px 0px rgba(189, 180, 214, 0.16);
}

.signInInputs::placeholder {
  color: #161616 !important;
  font-size: 16.638px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signInInputs:hover {
  border: 1px solid #86ddee !important;
  transition: all 0.3s ease;
}
.signInInputs:focus {
  border: 1px solid #00bce0 !important;
}

.forgotPasswordText {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  cursor: pointer !important;
}

/* SendOtp.js */
@media screen and (max-width: 1070px) {
  .formContainer {
    gap: 50px !important;
  }
}
@media screen and (max-width: 988px) {
  .sideImgBox {
    display: none !important;
  }
}

@media screen and (max-width: 568px) {
  .formContainer {
    padding: 50px 0px 0px 0px !important;
  }
}

@media screen and (max-width: 468px) {
  .formContainer {
    width: 100% !important;
  }
  .secInfoText {
    font-size: 13px !important;
  }
}

.otpExpTime {
  background: var(
    --Gradation,
    linear-gradient(90deg, #00bce0 58.85%, #f52bff 84.87%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.resendOtp {
  background: var(
    --new-gradation,
    linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin-top: 4px !important;
  cursor: pointer !important;
  float: right !important;
  padding-right: 20px !important;
  margin-bottom: 20px !important;
}
